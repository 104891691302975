import { Anchor, Paper, Stack } from '@mantine/core';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';

type TableProps = {
  closeMe: () => void;
};

const NoLicense: FC<TableProps> = ({ closeMe }) => {
  const handleClick = () => {
    closeMe();
  };

  return (
    <Paper bg="gray.0" p="lg">
      <Stack spacing="lg" align="center">
        <h3>There are no available device monitoring licenses</h3>

        <p>
          Please subscribe to additional "Device monitoring" licenses via the{' '}
          <Anchor component={Link} to="/store" onClick={handleClick}>
            Store
          </Anchor>
        </p>
      </Stack>
    </Paper>
  );
};

export default NoLicense;
