import { Anchor, Container, Stack, Text } from '@mantine/core';
import React from 'react';
import { Link } from 'react-router-dom';

import { IntegrationType } from '@portals/types';

interface ConfigurationMissingMessageProps {
  integration: IntegrationType<any>;
  linkTo: string;
  message: string;
  onLinkClick: () => void;
}

export function ConfigurationMissingMessage({
  integration,
  message,
  onLinkClick,
  linkTo,
}: ConfigurationMissingMessageProps) {
  // If integration exists and is active, no message is needed
  if (integration && integration.state === 'active') {
    return null;
  }

  let linkMessage = '';
  if (!integration) {
    linkMessage = 'Click here to configure';
  } else if (integration.state !== 'active') {
    linkMessage = 'Click here to activate';
  }

  return (
    <Container p="md">
      <Stack spacing="sm">
        <Text>{message}</Text>
        <Anchor component={Link} to={linkTo} onClick={onLinkClick}>
          {linkMessage}
        </Anchor>
      </Stack>
    </Container>
  );
}
